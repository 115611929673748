import React from "react";
import {
  SectionWrapper,
  Registration,
  PageSectionSimple,
  PageSectionColumns,
  getComponentMap,
  HubspotWebform,
  PageSectionComponentList,
} from "./sections";
import { EmbedSection } from "./sections/embed-section";
import { Tabbed } from "./sections/tabbed";
import { CarouselSlides } from "./sections/carousel-slides";
import { ReferenceList } from "./sections/reference-list";
import { ComparisonTable } from "./sections/compare-table";

const available = getComponentMap();

export const Page = ({ title, sections, slug }) => (
  <article>
    {sections
      ? sections.map((section, i) => {
          if (!section) {
            return (
              <SectionWrapper {...section} key={i} index={i}>
                Missing Component
              </SectionWrapper>
            );
          }
          switch (section._type) {
            case "pageSectionSimple":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <PageSectionSimple section={section} />
                </SectionWrapper>
              );

            case "pageSectionColumns":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  {section.style === "tabbed" ? (
                    <Tabbed section={section} />
                  ) : (
                    <PageSectionColumns section={section} />
                  )}
                </SectionWrapper>
              );

            case "pageSectionComponentList":
              return (
                <SectionWrapper
                  {...{ ...section, noHeaders: true }}
                  key={i}
                  index={i}
                >
                  {section.layout === "tabbed" ? (
                    <Tabbed section={section} />
                  ) : (
                    <PageSectionComponentList {...section} />
                  )}
                </SectionWrapper>
              );

            case "embedSection":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <EmbedSection
                    section={{ ...section }}
                    doc={{ _type: "page", title, slug }}
                  />
                </SectionWrapper>
              );

            case "pageComponent":
              if (available.has(section.name)) {
                const sectionObj = available.get(section.name);
                const Tag = sectionObj.component;

                return (
                  <SectionWrapper
                    {...{
                      ...section,
                      noHeaders: sectionObj.noHeaders,
                      desktopOnly: sectionObj.desktopOnly,
                    }}
                    key={i}
                    index={i}
                  >
                    <Tag section={section} />
                  </SectionWrapper>
                );
              }
              return (
                <SectionWrapper {...section} key={i}>
                  <p>Missing Component: {section.name}</p>
                </SectionWrapper>
              );

            case "webForm":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <Registration
                    section={{ ...section }}
                    doc={{ _type: "page", title, slug }}
                    supressMessage={true}
                  />
                </SectionWrapper>
              );

            case "webFormHubspot":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <HubspotWebform
                    section={{ ...section }}
                    doc={{ _type: "page", title, slug }}
                    className="mb-36"
                  />
                </SectionWrapper>
              );

            case "pageSectionSlides":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <CarouselSlides section={{ ...section }} />
                </SectionWrapper>
              );

            case "pageSectionList":
              return (
                <SectionWrapper {...section} key={i} index={i}>
                  <ReferenceList section={{ ...section }} />
                </SectionWrapper>
              );

            case "pageSectionCompare":
              return (
                <SectionWrapper {...section} key={i} index={i} noHeaders={true}>
                  <ComparisonTable section={section} />
                </SectionWrapper>
              );

            default:
              return JSON.stringify(section);
          }
        })
      : null}
  </article>
);
