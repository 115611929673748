import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../lib/helpers";
import { CardContext } from "../card/context";
import { QuoteCardContent } from "./quote-panels";
import { CarouselCards } from "./snowflake/signal-sources";
import { SLIDE_SPEED } from "./tabbed";

const quoteCarouselQuery = graphql`
  query QuoteCarouselQuery {
    docs: allSanityQuotable(
      sort: { fields: [orderRank] }
      filter: {
        isPublished: { eq: true }
        url: { eq: null }
        companies: {
          elemMatch: { mainImage: { asset: { _id: { ne: null } } } }
        }
      }
    ) {
      edges {
        node {
          ...Quotable
        }
      }
    }
  }
`;

export const QuoteCarousel = ({ section }) => {
  const { docs } = useStaticQuery(quoteCarouselQuery) || {};
  const quotes = section.category
    ? mapEdgesToNodes(docs).filter((d) =>
        d.categories.find((c) => c.slug.current === section.category)
      )
    : mapEdgesToNodes(docs);

  return quotes.length > 0 ? (
    <CarouselCards
      header={section.header}
      docs={quotes}
      speed={SLIDE_SPEED * 2}
      renderItem={(doc, i) => (
        <CardContext.Provider
          value={{
            authors: [
              {
                author:
                  doc?.authors.length > 0 ? doc.authors[0].author : doc?.author,
              },
            ],
            url: doc.url,
          }}
          key={i}
        >
          <div
            key={i}
            className="bg-white border border-dark-10 rounded-xl pb-8 px-5 mr-4 md:mr-0"
          >
            <QuoteCardContent
              {...{ q: doc, quotes, showCardLogo: true, boldText: true }}
            />
          </div>
        </CardContext.Provider>
      )}
    />
  ) : null;
};
