import React from "react";
import PortableText from "../portableText";
import { cn } from "../../lib/helpers";

import * as styles from "../global/global.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const ComparisonTable = ({ section }) => (
  <table className="w-full">
    <thead>
      <tr>
        <td className="w-1/3 py-7 pr-4 align-top">
          <h4 className="mb-3 text-2xl font-semibold leading-none sm:leading-tight tracking-tight">
            {section.header}
          </h4>
          <PortableText blocks={section._rawSummary} />
        </td>
        <td
          className={cn(
            "text-white p-4 py-8 rounded-t-xl w-1/3",
            styles.gradientPurple
          )}
        >
          <h4 className="mb-4 text-xl font-semibold">Common Room</h4>
          <PortableText
            blocks={section._rawCrDescription}
            className="text-lg text-light-80"
          />
        </td>
        <td>
          <div className="w-4"></div>
        </td>
        <td className="bg-white w-1/3 p-4 py-8 rounded-t-xl">
          <h4 className="mb-4 text-xl font-semibold">{section.competitor}</h4>
          <PortableText
            blocks={section._rawCompetitorDescription}
            className="text-lg text-secondary"
          />
        </td>
      </tr>
    </thead>
    <tbody className="align-top">
      {section.table.map((row, i) => (
        <tr
          key={i}
          className={row.isHeader ? "" : "border-b border-purple-default"}
        >
          <td
            className={cn(
              "pr-4 font-medium",
              row.isHeader ? "text-sm text-link uppercase pt-6" : "py-4"
            )}
          >
            {row.url ? (
              <a href="{row.url}" className="hover:underline">
                {row.label}
              </a>
            ) : (
              row.label
            )}
          </td>
          <td
            className={cn(
              "text-white font-medium leading-snug",
              styles.gradientPurple,
              row.isHeader ? null : "p-4 pr-6"
            )}
          >
            {row.native && (
              <div className="flex">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="sm"
                  className="mr-2 mt-1"
                />

                {row.native}
              </div>
            )}
          </td>
          <td></td>
          <td
            className={cn("bg-white leading-snug", row.isHeader ? null : "p-4")}
          >
            {row.compare}
          </td>
        </tr>
      ))}
      <tr>
        <td></td>
        <td className={cn("h-6 rounded-b-xl", styles.gradientPurple)}></td>
        <td></td>
        <td className="bg-white h-6 rounded-b-xl"></td>
      </tr>
    </tbody>
  </table>
);
