import { graphql } from "gatsby";
import React from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import {
  CustomerStories,
  PageSectionColumns,
  PageSectionSimple,
  QuoteCarousel,
  SectionWrapper,
} from "../components/sections";
import { ComparisonTable } from "../components/sections/compare-table";
import { toTextBlock } from "../lib/helpers";
import { ReferenceList } from "../components/sections/reference-list";
import PortableText from "../components/portableText";
import { CustomerLogosWall } from "../components/sections/logos";
import { Button } from "../components/button";
import {
  DEMO_PHRASE,
  DEMO_URL,
  SIGNUP_PHRASE,
} from "../components/sections/snowflake/navigation";

const PAGE_WIDTH = "large";

export const query = graphql`
  query CompetitorTemplateQuery($id: String!) {
    doc: sanityCompetitor(id: { eq: $id }) {
      _type
      _id
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      # mainImage {
      #   ...SanityImageSimple
      # }
      page {
        _type
        id
        _id
        sections {
          ... on SanityPageSectionSimple {
            _type
            _rawContent(resolveReferences: { maxDepth: 5 })
            anchor
            header
            subheader
            _rawSummary(resolveReferences: { maxDepth: 5 })
            layout
            background
            padding
            container
            mainImage {
              ...SanityImageSimple
            }
            image {
              ...SanityImage
              alt
              caption
              link
            }
            tableOfContents
            rounded
            constrainImages
          }
          ... on SanityPageSectionColumns {
            _type
            content {
              header
              mainImage {
                ...SanityImageSimple
              }
              _rawContent(resolveReferences: { maxDepth: 5 })
              author {
                ...SanityAuthor
              }
              layout
              icon
            }
            maxColumns
            anchor
            header
            subheader
            _rawSummary(resolveReferences: { maxDepth: 5 })
            layout
            background
            padding
            style
            container
            image {
              ...SanityImage
            }
          }
          ... on SanityPageComponent {
            _type
            name
            anchor
            header
            subheader
            _rawSummary(resolveReferences: { maxDepth: 5 })
            layout
            background
            padding
            container
            cards
          }
          ... on SanityPageComponent {
            _type
            name
            anchor
            header
            subheader
            _rawSummary(resolveReferences: { maxDepth: 5 })
            layout
            background
            padding
            container
            category
            cards
          }
        }
      }
      compare {
        _type
        _rawCrDescription(resolveReferences: { maxDepth: 5 })
        competitor
        _rawCompetitorDescription(resolveReferences: { maxDepth: 5 })
        anchor
        header
        _rawSummary(resolveReferences: { maxDepth: 5 })
        background
        padding
        container
        table {
          label
          url
          native
          compare
          isHeader
        }
      }
      alternatives {
        ... on SanityCompetitor {
          _type
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        ... on SanityPage {
          _type
          title
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
      }
      categories {
        _id
        title
        slug {
          current
        }
      }
    }
  }
`;

const CompetitorTemplate = ({ data }) => (
  <LayoutContainer doc={{ ...data.doc, backgroundColor: "lightLavender" }}>
    <PageHeadMeta title={data.doc.title} doc={data.doc} />
    {data.doc && <CompetitorPage {...data.doc} />}
  </LayoutContainer>
);

export default CompetitorTemplate;

export const CompetitorPage = ({
  title,
  compare,
  _rawExcerpt,
  categories,
  alternatives,
  page,
}) => (
  <>
    <SectionWrapper
      {...{
        noHeaders: true,
        background: "gradientOnly",
        name: "header",
        container: PAGE_WIDTH,
        layout: "centered",
      }}
    >
      <h1 className="text-4xl sm:text-6xl font-bold text-center">{title}</h1>
      <PortableText blocks={_rawExcerpt} className="text-center text-lg my-6" />
      <div className="flex justify-center mb-16">
        <Button
          text={DEMO_PHRASE}
          href={DEMO_URL}
          className="mr-2"
          bgColor="bg-black"
        />
        <Button text={SIGNUP_PHRASE} href={SIGNUP_PHRASE} />
      </div>
      <h4 className="text-secondary text-center text-sm mb-4">
        Trusted by the fastest-growing companies
      </h4>
      <CustomerLogosWall
        section={{ category: categories?.[0].slug.current, cards: 6 }}
      />
    </SectionWrapper>

    <SectionWrapper
      container={PAGE_WIDTH}
      {...page.sections.find((s) => s.anchor === "choose")}
    >
      <PageSectionColumns
        section={{
          ...page.sections.find((s) => s.anchor === "choose"),
          maxColumns: 3,
        }}
      />
    </SectionWrapper>

    <SectionWrapper container={PAGE_WIDTH}>
      <ComparisonTable section={compare} />
    </SectionWrapper>

    {/* @todo pull header and summary from parent page */}
    <SectionWrapper
      {...page.sections.find((s) => s.anchor === "stories")}
      container={PAGE_WIDTH}
    >
      <CustomerStories section={{ cards: 3 }} />
    </SectionWrapper>

    <SectionWrapper
      {...page.sections.find((s) => s.anchor === "get-started")}
      container={PAGE_WIDTH}
      layout="centered"
    >
      <PageSectionSimple
        section={page.sections.find((s) => s.anchor === "get-started")}
      />
    </SectionWrapper>

    <SectionWrapper
      {...page.sections.find((s) => s.anchor === "beyond")}
      container={PAGE_WIDTH}
    >
      <PageSectionColumns
        section={{
          ...page.sections.find((s) => s.anchor === "beyond"),
          maxColumns: 3,
        }}
      />
    </SectionWrapper>

    <SectionWrapper container={PAGE_WIDTH}>
      <QuoteCarousel
        section={{
          ...page.sections.find((s) => s.anchor === "quotes"),
          category: categories?.[0].slug.current,
        }}
      />
    </SectionWrapper>

    {/* <SectionWrapper
      container={PAGE_WIDTH}
      header="Compare alternatives"
      _rawSummary={toTextBlock(
        "Check whether Common Room is the best solution for your needs."
      )}
    >
      <ReferenceList section={{ list: alternatives }} />
    </SectionWrapper> */}

    <SectionWrapper
      container={PAGE_WIDTH}
      {...page.sections.find((s) => s.anchor === "faqs")}
    >
      <PageSectionColumns
        section={{
          ...page.sections.find((s) => s.anchor === "faqs"),
          maxColumns: 1,
        }}
      />
    </SectionWrapper>

    <SectionWrapper
      {...page.sections.find((s) => s.anchor === "get-started-footer")}
      container={PAGE_WIDTH}
      layout="centered"
    >
      <PageSectionSimple
        section={page.sections.find((s) => s.anchor === "get-started-footer")}
      />
    </SectionWrapper>
  </>
);
