import React from "react";
import { cn } from "../../lib/helpers";

const Container = ({ children, className, style, maxWidth }) => {
  return (
    <div
      className={cn(
        className,
        maxWidth || "max-w-screen-xl",

        maxWidth === "max-w-screen-sm"
          ? "px-6 lg:px-0 xl:px-6 max:px-0 box-border"
          : maxWidth === "max-w-screen-boundary"
          ? ""
          : "px-6 lg:px-8 xl:px-6 max:px-0 box-border",

        "mx-auto w-full"
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default Container;
