import React, { useEffect, useState } from "react";
import { TextPassage } from "./careers-blocks";

// https://developers.ashbyhq.com/docs/public-job-posting-api
const dataURL =
  "https://api.ashbyhq.com/posting-api/job-board/commonroom?includeCompensation=true";

const promotedDept = "Engineering";

export const tryFetch = async ({ url, values = {} }) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.log("FETCH RESPONSE ERROR", response);
    } else {
      // console.log("FETCH SUCCESS", response);
    }
    return response.json();
  } catch (e) {
    console.log("FETCH ERROR", e);
    return [];
  }
};

export const CareersListing = () => {
  const [careers, setCareers] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    tryFetch({
      url: dataURL,
    }).then((data) => {
      if (data.jobs) {
        setCareers(data.jobs);
        setDepartments(
          [...new Set(data.jobs.map((j) => j.department))].sort(
            (a, _b) => a === promotedDept
          )
        );
      }
    });
  }, []);

  return (
    <>
      <TextPassage
        header="We’re hiring"
        blocks={[
          "From day one, we’ve prioritized creating an inclusive culture where everyone can do their best work. We’d love to have you join us.",
        ]}
      />
      <div className="grid grid-cols-1 md:grid-cols-2/3-1/3 gap-1 md:gap-12">
        <div>
          {departments.length ? (
            <JobListPanel departments={departments} jobs={careers} />
          ) : (
            <p className="text-3xl text-dark-20">Loading...</p>
          )}
        </div>
        <div>
          <div className="bg-lavender p-6 rounded-xl mb-1 md:mt-12">
            <h4 className="font-semibold text-sm mb-2"> We're West Coasters</h4>
            <p className="text-lg mb-8">
              Our HQ is in Seattle, but we live across the US and British
              Columbia.
            </p>
            {/* <div className="group relative flex justify-center">
              <ImageBuilder src="/static/img/earth.png" />
            </div> */}
          </div>
          <div className="text-xl bg-green-900 p-4 rounded-xl">
            Don&apos;t see a match?
            <br />
            <a
              href="mailto:hello@commonroom.io"
              className="text-link hover:underline"
              target="_blank"
            >
              You should still say hello
            </a>
            .
          </div>
        </div>
      </div>
    </>
  );
};

export const JobListPanel = ({ departments, jobs, hideDept, total }) =>
  departments.map((dept, d) => {
    const filteredJobs = jobs.filter((job) => {
      return job.department === dept;
    });

    return (
      <div key={dept}>
        {!hideDept && (
          <h3 className="text-sm font-semibold text-dark-40 mt-6 mb-2 ml-4">
            {dept}
            <span className="text-xs font-normal bg-dark-10 px-2 py-1 rounded-full ml-2">
              {total || filteredJobs.length} opening
              {total > 1 || filteredJobs.length > 1 ? "s" : ""}
            </span>
          </h3>
        )}
        <ul className="text-base">
          {filteredJobs &&
            filteredJobs.sort().map((job, i) => (
              <li key={`${d}-${i}`}>
                <a
                  className="p-4 rounded-lg bg-white hover:shadow-md transform transition-card scale-100 hover:scale-105 duration-200 block border border-dark-20 mb-3"
                  href={job.jobUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <p className="text-lg font-semibold text-link">{job.title}</p>
                  {job.company && <p className="text-dark-80">{job.company}</p>}
                  <p className="text-secondary">{job?.location}</p>
                </a>
              </li>
            ))}
        </ul>
      </div>
    );
  });
