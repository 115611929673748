/* eslint-disable react/display-name */
import React, { useContext } from "react";
import { SiteContext } from "../../global/site-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faPodcast } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faLinkedin,
  faTwitter,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons";
import { pageDocPath } from "../../../../helpers";
import { cn } from "../../../lib/helpers";

export const JOIN_SLACK_PATH = "/community/";
// @todo use site settings
export const YOUTUBE_URL = "https://www.youtube.com/@CommonRoomHQ";
export const LINKEDIN_URL = "https://www.linkedin.com/company/common-room-hq";
export const TWITTER_URL = "https://twitter.com/commonroomhq";

const CHANNELS = [
  {
    title: "LinkedIn",
    url: LINKEDIN_URL,
    component: () => <FontAwesomeIcon icon={faLinkedin} />,
    icon: faLinkedin,
    color: "brand-linkedin",
  },
  {
    title: "YouTube",
    url: YOUTUBE_URL,
    component: () => <FontAwesomeIcon icon={faYoutube} />,
    icon: faYoutube,
    color: "brand-youtube",
  },
  {
    title: "Twitter",
    url: TWITTER_URL,
    component: () => <FontAwesomeIcon icon={faTwitter} />,
    icon: faTwitter,
    color: "brand-twitter",
  },
  {
    title: "Apple Podcasts",
    url: "/podcasts/apple/",
    component: () => <FontAwesomeIcon icon={faPodcast} />,
    icon: faPodcast,
    color: "brand-applepodcasts",
  },
  {
    title: "Spotify",
    url: "/podcasts/spotify/",
    component: () => <FontAwesomeIcon icon={faSpotify} />,
    icon: faSpotify,
    color: "brand-spotify",
  },
  {
    title: "Events",
    url: "/events/",
    component: () => <FontAwesomeIcon icon={faCalendarDays} />,
    icon: faCalendarDays,
    internal: true,
    size: "md",
  },
];

export const ChannelsButtons = ({ section }) => {
  const site = useContext(SiteContext);
  const path = pageDocPath(site?.doc);

  return (
    <div className="flex flex-wrap md:flex-nowrap items-center justify-center gap-2 rounded-2xl md:rounded-3xl text-sm pb-3">
      {CHANNELS.map((channel, i) => {
        const attributes = !channel.internal ? { target: "_blank" } : {};

        return channel.url !== path ? (
          <a
            href={channel.url}
            rel="noopener"
            key={i}
            onClick={() => {
              site.metrics.logClick(`channel--${channel.title.toLowerCase()}`);
            }}
            className={cn(
              "flex gap-1 justify-center items-center p-2 sm:px-3 rounded-lg bg-white font-medium shadow-md transform transition-card scale-100 hover:scale-110 whitespace-nowrap",
              `text-${channel.color || "black"}`
            )}
            {...attributes}
          >
            <FontAwesomeIcon
              icon={channel.icon}
              size={channel.size || "lg"}
              className="rounded-md"
            />
            <span>{channel.title}</span>
          </a>
        ) : null;
      })}
    </div>
  );
};
