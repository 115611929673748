import React from "react";
import { toPlainText } from "../../../lib/helpers";
import { PodcastLinks } from "./podcast";

export const ResourcesHeader = ({ title, tag, suffix }) => {
  const isPodcast = ["podcast", "podcast/all"].includes(tag?.slug.current);

  return (
    <div className="mt-20 text-center">
      <div className="max-w-screen-sm mx-auto">
        <h1 className="text-3xl lg:text-4xl mb-2 font-bold tracking-tight">
          {title || "Explore what's possible with Common Room"}
          {suffix}
        </h1>
        {tag && (
          <div className="max-w-screen-sm mx-auto">
            {tag.headline && (
              <h2 className="text-xl mb-4 text-secondary">{tag.headline}</h2>
            )}
            <h3 className="text-lg font-medium tracking-tight">
              {toPlainText(tag._rawExcerpt)}
            </h3>
          </div>
        )}
      </div>
      {isPodcast && <PodcastLinks className="mt-2" path={tag?.slug.current} />}
    </div>
  );
};
