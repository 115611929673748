import React, { useContext } from "react";
import PortableText from "../portableText";
import { cn } from "../../lib/helpers";
import { SiteContext } from "../global/site-context";
import { SectionContext } from "./context";
import { QuotePanels } from "./quote-panels";
import { pageDocPath } from "../../../helpers";
import { ImageBuilder } from "../global/image-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas as iconSet } from "@fortawesome/free-solid-svg-icons";
import Masonry from "react-masonry-css";

import * as styles from "./column-content.module.css";

// @todo use FeaturedMedia section vs overloading column

// @todo unify with header from section wrapper

export const PageSectionColumns = ({ section }) => {
  const siteContext = useContext(SiteContext);
  const sectionContext = useContext(SectionContext);
  const columnLimit = section.maxColumns || section.content.length;
  const path = pageDocPath(siteContext?.doc);

  const simpleColumnClasses = cn(
    "grid items-start grid-cols-1",
    columnLimit > 1 ? "sm:grid-cols-2" : "",
    `md:grid-cols-${columnLimit}`
  );
  const isFeaturedMedia =
    ["featureRight", "featureLeft"].includes(section.style) &&
    section.content.find((c) => c.mainImage);

  // @todo FeaturedMedia setting
  const specialClass =
    isFeaturedMedia && section.container === "large" ? "px-4 sm:px-0" : "";

  const layoutMap = new Map();
  layoutMap.set("centered", "text-center");

  // section schema: style
  const styleMap = new Map([
    ["centered", cn("centered", simpleColumnClasses)],
    ["featureLeft", cn("grid sm:grid-cols-2 md:grid-cols-2/3-1/3")],
    ["featureRight", cn("grid sm:grid-cols-2 md:grid-cols-1/3-2/3")],
    ["unevenColumns", cn("grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6")],
    ["tiles", "grid grid-cols-2 md:grid-cols-4"],
    ["overlap", "grid md:grid-cols-3"],
    ["graphicBento", "grid md:grid-cols-6"],
  ]);

  // attach styles from css file matching name
  const styleName = sectionContext.isDarkBg
    ? `${section.style}Dark`
    : section.style;
  const styleClass = cn(
    styleMap.has(section.style)
      ? styleMap.get(section.style)
      : simpleColumnClasses,
    styleName in styles ? styles[styleName] : null,
    path === "/developers/" ? styles.developersIcons : null
  );
  const gapClass =
    section.style === "panels" || columnLimit === 1
      ? "gap-1"
      : isFeaturedMedia
      ? "gap-6 gap-x-12"
      : styleClass
      ? // @todo
        styleName === "unevenColumns"
        ? "gap-6 md:gap-6"
        : styleName === "icon"
        ? "gap-8"
        : styleName === "tiles"
        ? "gap-4"
        : styleName === "overlap"
        ? "gap-8"
        : section.style === "panelsColors"
        ? "gap-8"
        : columnLimit > 3
        ? "gap-2"
        : "gap-2 lg:gap-4"
      : "gap-24 gap-y-12";

  return section.style === "quotes" ? (
    <QuoteSection section={section} />
  ) : section.style === "bento" ? (
    <Bento section={section} columnLimit={columnLimit} />
  ) : section.content ? (
    <div
      className={cn(
        gapClass,
        styleClass,
        specialClass,
        section.style === "overlap" ? "xl:pt-24" : null
      )}
    >
      {section.content.map((column, i) => (
        <div
          key={i}
          className={cn(
            isFeaturedMedia
              ? "flex flex-wrap sm:content-start items-center sm:h-full"
              : null,
            isFeaturedMedia
              ? column.mainImage
                ? "order-1 sm:order-none justify-center mb-8"
                : "order-2 sm:order-none"
              : null,
            columnLimit === 1 && layoutMap.has(section.layout)
              ? layoutMap.get(section.layout)
              : null,
            column.layout === "right"
              ? "text-right"
              : column.layout === "centered"
              ? "text-centered"
              : "h-full",
            section.style === "overlap" && section.layout === "centered"
              ? "text-center"
              : null
          )}
        >
          {column.icon && (
            <div className="text-purple-lavender text-center block py-4">
              <FontAwesomeIcon
                icon={iconSet[column.icon]}
                size="2x"
                className="max-w-8"
              />
            </div>
          )}
          {section.style === "icon" ? (
            <div className="flex items-center mb-2">
              <ImageBuilder
                image={column.mainImage}
                width={18}
                alt="icon"
                className="mr-2"
              />
              <h3 className="font-medium text-sm">{column.header}</h3>
            </div>
          ) : (
            <>
              {column.header && (
                <h3
                  className={cn(
                    column.mainImage
                      ? "font-bold text-xl-homepage mb-4"
                      : "font-medium text-lg mb-4",
                    section.style === "overlap"
                      ? "text-link text-xl font-semibold xl:mb-16"
                      : null
                  )}
                >
                  {column.header}
                </h3>
              )}
              <ImageBuilder
                image={column.mainImage}
                alt={column.header}
                width={
                  isFeaturedMedia ? "1280" : Math.round(1024 / columnLimit)
                }
                className={cn("mb-6", !isFeaturedMedia ? "w-full" : null)}
              />
            </>
          )}
          {column._rawContent && (
            <div
              className={cn(
                isFeaturedMedia ? "text-lg leading-normal" : null,
                section.style === "lightContent" ? "text-secondary" : null,
                section.style === "icon" ? "text-sm text-gray-900" : null
              )}
            >
              {column._rawContent && (
                <PortableText blocks={column._rawContent} />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (
    "No Column Content"
  );
};

const QuoteSection = ({ section }) => (
  <QuotePanels
    noBox={true}
    quotes={section.content.map((col, i) => {
      const indexMod = i % 3;
      return {
        text: col._rawContent ? (
          <PortableText blocks={col._rawContent} />
        ) : null,
        image:
          indexMod === 0
            ? "emoji-face-right.svg"
            : indexMod === 1
            ? "emoji-avocado.svg"
            : "emoji-nessy.svg",
        color:
          indexMod === 0 ? "lavender" : indexMod === 1 ? "mint" : "green-900",
        className: "h-16 w-14",
        author: col.author,
      };
    })}
  />
);

const Bento = ({ section, columnLimit }) => (
  <Masonry
    breakpointCols={{
      default: 3,
      1020: 2,
      600: 1,
    }}
    className={styles.masonryGrid}
    columnClassName={styles.masonryGridColumn}
  >
    {section.content.map((column, i) => (
      <div key={i}>
        {column.header && (
          <h3 className="font-bold text-xl-homepage mb-4">{column.header}</h3>
        )}
        {column._rawContent && <PortableText blocks={column._rawContent} />}
        <ImageBuilder
          image={column.mainImage}
          alt={column.header}
          width={Math.round(1024 / columnLimit)}
        />
      </div>
    ))}
  </Masonry>
);
