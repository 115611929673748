// @ts-check
import { differenceInHours, isFuture } from "date-fns";
import { pageDocPath } from "../../helpers";
import { STASHED_ATTRIBUTION_TIME_LIMIT_HOURS } from "../components/web-form";
import clientConfig from "../../client-config";
import imageUrlBuilder from "@sanity/image-url";

/**
 * @param  {...(string|null|undefined|false)} args
 * @returns {string}
 */
export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

/**
 * @param {{edges?: {node?: unknown}[]}} data
 * @returns {unknown[]|null}
 */
export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

/**
 * @param {{slug?: Record<string, unknown>}} docs
 * @returns {unknown}
 */
export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

/**
 * @param {{publishedAt: string | number | Date }} published
 * @returns {boolean}
 */
export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(new Date(publishedAt));
}

/**
 *
 * @param {string} string
 * @returns {string}
 */
export const toFirstCapital = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const CARD_SUMMARY_MAX = 310;
const TITLE_MULTIPLIER = 1.5;

/**
 * @param {{ str: string, titleStr: string, max: number }} data
 * @returns {string}
 */
export const truncateText = ({ str, titleStr, max = CARD_SUMMARY_MAX }) => {
  const textLength = titleStr
    ? Math.round(max - titleStr.length * TITLE_MULTIPLIER)
    : max;

  if (textLength < 0) {
    return "";
  }

  const trimmed = str.slice(0, textLength);

  return trimmed.length < textLength
    ? trimmed
    : trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" "))) +
        "...";
};

/**
 *
 * @param {{
 *  crop?: unknown,
 *  hotspot?: unknown,
 *  asset: Record<string, unknown>
 * }=} source
 * @returns {{
 *  asset: { _ref: unknown },
 *  crop?: unknown,
 *  hotspot?: unknown
 * }|null}
 */
export function buildImageObj(source = { asset: {} }) {
  if (!source.asset) {
    return null;
  }

  /**
   * @type {{ asset: { _ref: unknown }, crop?: unknown, hotspot?: unknown}}
   */
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

/**
 * @param {{
 *  crop?: unknown,
 *  hotspot?: unknown,
 *  asset: Record<string, unknown>
 * }=} source
 * @returns {imageUrlBuilder|null}
 */
export function imageBuilder(source) {
  const builder = imageUrlBuilder(clientConfig.sanity);
  const obj = buildImageObj(source);
  // @ts-ignore
  return obj ? builder.image(obj) : null;
}

/**
 * @param {{_type: string, children?: {text: string}[]}[] | null} blocks
 * @returns {string}
 */
export function toPlainText(blocks) {
  return blocks
    ? blocks
        .map((block) =>
          block._type !== "block" || !block.children
            ? ""
            : block.children.map((child) => child.text).join("")
        )
        .join("\n\n")
    : "";
}

/**
 * @param {string} text
 * @returns {object}
 */
export const toTextBlock = (text, mark = "p") => {
  return [
    {
      _type: "block",
      children: [{ _type: "span", marks: [], text }],
      markDefs: [],
      style: mark,
    },
  ];
};

/**
 * @param {string} hex
 * @returns {{r: number, g: number, b: number} | null}
 */
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * @param {string} email
 * @returns {boolean}
 */
export const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

/**
 * Only for client-side interactions.
 *
 * @param {string} href
 * @returns {string}
 */
const isBrowser = typeof window !== "undefined";
export const currentPath = (
  href = isBrowser ? window.location.pathname : ""
) => {
  return href.replace(/\/$/, "").replace(/^\//, "") || "";
};

/**
 * @param {string} text
 */
export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// Make this a page setting
const SLIM_PATHS = [
  "/join-slack/",
  "/hello/pipeline/",
  "/hello/cmo/",
  "/hello/dark-funnel-demand/",
  "/hello/discover-pipeline-dark-funnel/",
  "/hello/growth-hacks/",
  "/hello/demand-curve/",
  "/referral-program/",
  "/series/mavericks/",
  "/series/101/",
  "/blog/",
];
/**
 * @param {*} site
 * @returns {boolean}
 */
export const isThinPage = (site) =>
  site?.isHomepage ||
  SLIM_PATHS.includes(pageDocPath(site?.doc)) ||
  [
    "playbook",
    "company",
    "integration",
    "story",
    "post",
    "signal",
    "redirect",
    "prospect",
    "prompt",
    "competitor",
  ].includes(site?.doc?._type) ||
  ["narrow", "thin", "large"].includes(site?.doc?.pageWidth);

/**
 * Handle true in session and local storage.
 *
 * @param {*} value
 * @returns {boolean}
 */
export const dualTypeTrue = (value) => value === true || value === "true";

/**
 * Set localStorage value plus created date and origin value
 * @param {{storage: *, value: string, id: string, skipOrigin: boolean}} param0
 */
export const setStashed = ({ id, value, storage, skipOrigin }) => {
  if (!skipOrigin && (!storage.getItem(id) || storage.getItem(id) === "")) {
    storage.setItem(`origin.${id}`, value);
    storage.setItem(`origin.${id}.created`, new Date().toISOString());
  }

  storage.setItem(id, value);
  storage.setItem(`${id}.created`, new Date().toISOString());
};

/**
 * Get localStorage value when within time window
 * @param {{storage: *, id: string}} param0
 * @returns {string}
 */
export const getStashed = ({ storage, id }) => {
  const created = storage.getItem(`${id}.created`);
  const stashedVal = storage.getItem(id);

  return differenceInHours(new Date(), new Date(created)) <
    STASHED_ATTRIBUTION_TIME_LIMIT_HOURS
    ? stashedVal
    : null;
};

/**
 * @param {{storage: *, id: string}} param0
 */
export const removeStashed = ({ id, storage }) => {
  storage.removeItem(id);
  storage.removeItem(`${id}.created`);
};

/**
 *
 * @param {string} str
 */
export const hashCode = (str) => {
  return Math.abs(
    Array.from(str).reduce(
      (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
      0
    )
  );
};
